import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFetchDocuments } from "../../../../hooks";
import Table, { ColumnsType, TableProps } from "antd/es/table";
import {
  COPILOTS_NAME,
  MODAL_TYPE,
  getLocalDateTimeString,
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../../../utils";
import { AppPagination, FileUpload, Loading, NotAccessibleFallback } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import "./../copilots-details.scss";
import { useNavigate } from "react-router";
import { Moment } from "moment";
import { DatePicker } from "../../../../components";
import { RangeValue } from "rc-picker/lib/interface";
import { Button, Select, Empty } from "antd";
import {
  CaretDownFilled,
  CaretUpFilled,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { getClass, getPCPStatusClass, rangePresets } from "../helper";
import ExpandedRowContent from "../expanded-row-content/ExpandedRowContent";
import { SorterResult } from "antd/es/table/interface";
import { StopProcessIcon } from "../../../../assets/icons";
import { documentService } from "../../../../api/document";
import { useParams } from "react-router-dom";
import { openModal, RootState, setSelectedDateRange } from "../../../../store";
import moment from "moment";
import HoldFilter from "../hold-filter/HoldFilter";
import { EvidenceContentJSON } from "../../../evidence-viewer/evidence-viewer-container";

const { Option } = Select;



export interface IClaimsDataType {
    key: string;
    triple_subject: string;
    triple_predicate: string;
    triple_object: string;
    edit_code: string;
    state: string;
    lob: string;
    external_document: string | null;
    node_id: number;
    created_at?: string;
    updated_at?: string;
  }

interface Props {
  copilotName: string;
}

const ClaimsData = ({ copilotName }: Props) => {
  const {
    claimsData,
    isDocumentsLoading,
    paginationObj,
    sortFilter,
    isAuthorized,
    defaultFromDate,
    defaultToDate,
    searchTerm,
    setSearchTerm,
    setSearchDocumentUUID,
    setSortBy,
    fetchClaimsData,
    totalDocuments
  } = useFetchDocuments(copilotName);
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { setSort, sort } = sortFilter;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchUuid, setSearchUuid] = useState("");
  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const searchInputRef = useRef(null as any);
  const [sorterState, setSorterState] = useState() as any;
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    { key: string; status: string; sessionId: string }[]
  >([]);
  const { copilotId } = useParams();
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const isFileImported = useSelector((state: RootState) => state.ui.isFileImported);
  
  const { fromDate: selectedFromDate, toDate: selectedToDate } = useSelector(
    (state: RootState) => state.ui.selectedDateRange
  );

  const onTableChange: TableProps<IClaimsDataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    if (searchInputRef.current && document.activeElement === searchInputRef.current.input) {
      return;
    }
    setSorterState(sorter);
    const firstSorter = sorter as SorterResult<IClaimsDataType>;
    switch (firstSorter.order) {
      case "descend":
        setSortBy(`-${firstSorter.field}`);
        break;
      case "ascend":
        setSortBy(firstSorter.field as string);
        break;
      default:
        setSortBy("");
        break;
    }
  };

  const handleExpand = (expanded: boolean, record: IClaimsDataType) => {
    if (expanded) {
      setExpandedRowKey(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };

  const handleSearch = (event: any, type?: string) => {
    if (type === "uuid") {
      setSearchDocumentUUID(searchUuid);
    } else {
      setSearchTerm(searchText);
    }
    onPaginationChange(1, pageSize);
  };

  const handleChange = (event: any, type?: string) => {
    if (type === "uuid") {
      setSearchUuid(event.target.value);
      if (event.target.value == "") {
        setSearchDocumentUUID("");
        onPaginationChange(1, pageSize);
      }
    } else {
      setSearchText(event.target.value);
      if (event.target.value == "") {
        setSearchTerm("");
        onPaginationChange(1, pageSize);
      }
    }
  };

  const handleSort = (column: string) => {
    if (!sorterState) {
      setSortBy(`${column}`);
    } else if (sorterState.order === "ascend") {
      setSortBy(`-${column}`);
    } else if (sorterState.order === "descend") {
      setSortBy(``);
    }
  };

  const getSortIcon = (column: string) => {
    let caretUpFilledStyle = { color: "#0000004a" };
    let caretDownFilledStyle = { marginTop: "-0.3em", color: "#0000004a" };
    if (sorterState && sorterState.field === column) {
      if (sorterState.order === "ascend") {
        caretUpFilledStyle = { color: "#000000" };
        caretDownFilledStyle = { marginTop: "-0.3em", color: "#0000004a" };
      } else if (sorterState.order === "descend") {
        caretUpFilledStyle = { color: "#0000004a" };
        caretDownFilledStyle = { marginTop: "-0.3em", color: "#000000" };
      }
    }
    return (
      <>
        <CaretUpFilled style={caretUpFilledStyle} />
        <CaretDownFilled style={caretDownFilledStyle} />
      </>
    );
  };





  const dataSource: IClaimsDataType[] = useMemo(
    () =>
      claimsData.map((document: IClaimsDataType) => ({
        key: document.node_id.toString(),
        triple_subject: document.triple_subject,
        triple_predicate: document.triple_predicate,
        triple_object: document.triple_object,
        edit_code: document.edit_code,
        state: document.state,
        lob: document.lob,
        external_document: document.external_document,
        node_id: document.node_id,
        created_at: document.created_at 
          ? getLocalDateTimeString(document.created_at) 
          : undefined,
        updated_at: document.updated_at 
          ? getLocalDateTimeString(document.updated_at) 
          : undefined,
      })),
    [claimsData, isFileImported]
  );
  


  const handleSelectRow = (checked: boolean, key: string, status: string, sessionId: string) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, { key, status, sessionId }]);
    } else {
      setSelectedRowKeys(selectedRowKeys.filter((item) => item.key !== key));
    }
  };
  
  

  const renderStatusOptions = ( PassedCopilotName:string | undefined) => {
    switch (PassedCopilotName) {
      case COPILOTS_NAME.PCP:
        return (
          <>
            <Option value="">ALL</Option>
            <Option value="Fetching">FETCHING</Option>
            <Option value="Failed">FAILED</Option>
            <Option value="Succeeded">SUCCEEDED</Option>
            <Option value="Processing">PROCESSING</Option>
          </>
        );
      case COPILOTS_NAME.HEDIS_INSIGHTS:
        return (
          <>
            <Option value="">ALL</Option>
            <Option value="FAILED">FAILED</Option>
            <Option value="ON_HOLD">ON HOLD</Option>
            <Option value="PROCESSING-PAGES">PROCESSING-PAGES</Option>
            <Option value="COMPLETED-WITH-ERRORS">COMPLETED-WITH-ERRORS</Option>
            <Option value="COMPLETED">COMPLETED</Option>
            <Option value="LOADING-FILE">LOADING-FILE</Option>
          </>
        );
      default:
        return (
          <>
            <Option value="">ALL</Option>
            <Option value="ERROR">FAILED</Option>
            <Option value="ON_HOLD">ON HOLD</Option>
            <Option value="PROCESSING">PROCESSING</Option>
          </>
        );
    }
  };

  const renderStatus = (status: string) => (

      <div className="status" style={{ color: getClass(status) }}>
        <p style={{ color: getClass(status) }}>{status}</p>
      </div>
  );
  
  

  const getColumns = (): ColumnsType<IClaimsDataType> => {
    const columns = [
      {
        title: "Subject",
        dataIndex: "triple_subject",
        key: "triple_subject",
        className: "text-wrap-class",
       
      },
      {
        title: "Predicate",
        dataIndex: "triple_predicate",
        key: "triple_predicate",
        className: "text-wrap-class",
        
      },
      {
        title: "Object",
        dataIndex: "triple_object",
        key: "triple_object",
        className: "text-wrap-class",
        
      },
      {
        title: "Edit Code",
        dataIndex: "edit_code",
        key: "edit_code",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",        
      },
      {
        title: "Line of Business",
        dataIndex: "lob",
        key: "lob",

      },
      {
        title: "External Document",
        dataIndex: "external_document",
        key: "external_document",
      }
    ];
  
    return columns.filter((column) => column !== null) as ColumnsType<IClaimsDataType>;
  };
  

  useEffect(() => {
    // setSelectedRowKeys([]);
    setIsSelectAllChecked(false);
  }, [page, pageSize]);

  useEffect(() => {
    fetchClaimsData();
  }, [fetchClaimsData, isFileImported, selectedFromDate, selectedToDate, searchTerm, sort]);

  const onRangeChange = (dates: RangeValue<Moment>, dateStrings: string[]) => {
    if (dates) {
      dispatch(
        setSelectedDateRange({
          fromDate: dates[0]?.toString() ?? null,
          toDate: dates[1]?.toString() ?? null,
        })
      );
    } else {
      dispatch(
        setSelectedDateRange({
          fromDate: defaultFromDate?.toString() ?? null,
          toDate: defaultToDate?.toString() ?? null,
        })
      );
    }
  };

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }


  
  

  const CustomExpandIcon = ({ expanded, onExpand, record }: any) => {
    const iconStyle = { fontSize: "20px", cursor: "pointer" };
    if (expanded) {
      return (
        <Button style={{ padding: "5px" }} onClick={(e) => onExpand(record, e)}>
          <MinusOutlined style={iconStyle} />
        </Button>
      );
    }
    return (
      <Button style={{ padding: "5px" }} onClick={(e) => onExpand(record, e)}>
        <PlusOutlined style={iconStyle} />
      </Button>
    );
  };

const handleImportModal = (choice?: string) => {
    if(choice === "blob")
        dispatch(openModal(MODAL_TYPE.UPLOAD_CSV_FILE));
    else 
        dispatch(openModal(MODAL_TYPE.IMPORT_CSV_FILE))
}
  
  return (
    <div>
      <div style={{ display: "flex"}} className="tabHeader-claims">
        <div className="flex gp-10">

            <Button  className="outline" onClick={()=>handleImportModal('blob')}> 
                Upload CSV
            </Button>
            <Button  className="outline" onClick={()=>handleImportModal('local')}> 
                Import From Local 
            </Button>
        </div>

        <AppPagination
            className="tr m-b paginationDiv"
            {...{
              showSizeChanger: true,
              current: page,
              total: totalDocuments,
              pageSize,
              pageSizeOptions,
              onChange: onPaginationChange,
            }}
          />
      </div>
      <div className="total-count-container" style={{'marginBottom':'1em'}}>
        <span className="total-count-label">Total Records : </span>
        <span className="total-count-value">{totalDocuments}</span>
      </div>
      <Table
        className="document-container-table"
        columns={getColumns()}
        dataSource={dataSource}
        onChange={onTableChange}
        loading={isDocumentsLoading}
        pagination={false}
        locale={{ emptyText: <Empty>No Data Found</Empty> }}
      />
    </div>
  );
};

export default ClaimsData;
