export * from "./copilot-details-container";
import { COPILOTS_NAME } from "../../utils";

export const COPILOT_DETAILS_TAB = {
  SERVICE_STATUS: "Overview",
  DOCUMENTS: "Document Logs",
  // PROCESSING_REPORT: "Processing Status & Logging",
  UTILIZATION: "Utilization",
};
export const COPILOT_DETAILS_TAB_CLAIMS = {
  SERVICE_STATUS: "Overview",
  DOCUMENTS: "Documents",
  EDIT_CODE_SOP_MAPPING: "Edit Code - SOP Mapping",
  SEARCH_LOGS: "Search Logs",
  CLAIMS_DATA: "Claims Data",
  SETTINGS: "Settings",
}

export const COPILOT_DETAILS_TAB_PI = {
  SERVICE_STATUS: "Overview",
  QA: "Questions and Answers",
  FEEDBACKS: "Feedback",
  UTILIZATION: "Utilization",
};

export const COPILOT_DETAILS_TAB_PEGA = {
  SERVICE_STATUS: "Overview",
  DOCUMENTS: "Document Logs",
  UTILIZATION: "Utilization",
  KEYWORDS: "Configuration",
};

export const COPILOT_DETAILS_TABS = {
  SERVICE_STATUS: "Overview",
  QA: "Questions and Answers",
  FEEDBACKS: "Feedback",
  UTILIZATION: "Utilization",
  DOCUMENTS: "Document Logs",
  PROCESSING_REPORT: "Processing Status & Logging",
  KEYWORDS: "Configuration",
};

export const COPILOT_CCA_TAB = {
  SERVICE_STATUS: "Overview",
  DOCUMENTS: "Request Logs",
  TOKENUTILIZATION: "Utilization ",
};

export const COPILOT_ANG_TAB = {
  SERVICE_STATUS: "Overview",
  DOCUMENTS: "Request Logs",
  UTILIZATION: "Utilization",
};

export const COPILOT_PCP_TAB = {
  SERVICE_STATUS: "Overview",
  DOCUMENTS: "Document Logs",
  UTILIZATION: "Utilization",
};

export const getCopilotDetailsTabs = (copilotName: string) => {
  switch (copilotName) {
    case COPILOTS_NAME.CAREGPT_CHATBOT:
      return COPILOT_DETAILS_TAB_PI;
    case COPILOTS_NAME.PEGA_PRIOR_AUTH:
      return COPILOT_DETAILS_TAB_PEGA;
    case COPILOTS_NAME.CCA:
      return COPILOT_CCA_TAB;
    case COPILOTS_NAME.UM:
    case COPILOTS_NAME.ANG_INSIGHTS:
      return COPILOT_CCA_TAB;
    case COPILOTS_NAME.PCP:
      return COPILOT_PCP_TAB;
    case COPILOTS_NAME.CLAIMS:
      return COPILOT_DETAILS_TAB_CLAIMS;
    default:
      return COPILOT_DETAILS_TAB;
  }
};
